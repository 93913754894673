class WebSiteObjectFilters extends WebPageComponentClass {
    constructor(element) {
        super(element);

        this.fields = new Array();
        this.defaultFilters = new Array();

        this.uri = this.element.dataset.Uri;
        this.filterForm = null;

        // TODO: provide base uri server side.
        this.baseUri = this.uri.split(";", 1);

        this.determineElements();
        this.attachHandlers();
    }

    attachHandlers() {
        if (this.add !== null)
            this.attachHiddenFilters();
    }

    bind() {
        this.registerHiddenFilters();
        this.registerDefaultFilters();
    }

    determineElements() {
        let query = new DomQuery(this.element);

        this.toolbar = query.getChild(WithClass("Toolbar"));
        this.header = new DomQuery(this.toolbar).getChild(WithClass("Header"));
        this.progress = new Progress(query.getChild(WithClass("Progress")));
        this.contents = query.getChild(WithClass("Contents"));
        this.feedback = new DomQuery(this.toolbar).getChild(WithClass("Feedback"));

        query = new DomQuery(this.header);

        this.toggle = query.getChild(WithClass("ToggleFilters"));
        this.toggle.addEventListener(
            "click",
            (event) => {
                new HtmlClassSwitch(this.element, "Active").toggle();
            }
        )

        this.clear = query.getChild(WithClass("ClearFilters"));

        if (this.clear !== null) {
            this.clear.addEventListener(
                "click",
                () => {
                    this.clearFilters();
                }
            )
        }

        query = new DomQuery(this.toolbar);
        this.add = query.getChild(WithClass("Add"));
        this.values = query.getDescendants(WithClass("FilterValue"));

        const defaultFilters = query.getDescendants(WithClass("DefaultFilter"));

        for (const defaultFilter of defaultFilters)
            this.defaultFilters.push(new Filter(defaultFilter));
    }

    registerDefaultFilters() {
        for (const defaultFilter of this.defaultFilters)
            this.registerFilter(defaultFilter.field, true);
    }

    registerHiddenFilters() {
        if (this.filterForm !== null) {
            const form = this.filterForm.component;
            const section = form.childComponents[0];

            for (const child of section.childComponents)
                this.registerFilter(child.element, false);
        }
    }

    createClearFilterHandler(value) {
        const name = value.childNodes[1].dataset.Name;

        return (event) => {
            this.toolbar.removeChild(value);

            for (const field of this.fields) {
                if (field.getName().indexOf(name, field.getName().length - name.length) !== -1) {
                    field.setValue("");
                    this.filter();
                }
            }
        };
    }

    clearFilters() {
        this.reload(this.baseUri, true);
    }

    attachHiddenFilters() {
        this.button = new DomQuery(this.add).getChild(WithTagName("BUTTON"));
        this.button.addEventListener(
            "click",
            (event) => {
                this.toggleAddForm()
            }
        );

        this.addExpanded = new HtmlClassSwitch(this.add, "Expanded");

        this.filterForm = new DomQuery(this.add).getChild(WithTagName("FORM"));
        this.filterForm.childNodes[1].childNodes[0].addEventListener(
            "click",
            () => {
                this.toggleAddForm();
                this.filter();
            }
        );

        for (const value of this.values) {
            const close = new DomQuery(value).getChild(WithClass("Close"));
            close.onclick = this.createClearFilterHandler(value);
        }
    }

    filter() {
        this.reload(this.getUri(), false);
    }

    reloadContents(contents) {
        interactivityRegistration.detach(this.contents);
        this.contents.replaceWith(contents);
        this.contents = contents;
        interactivityRegistration.attach(this.contents);
    }

    reloadToolbar(newToolbar, reset) {
        this.fields = this.fields.filter(field => !reset && this.defaultFilters.some(defaultFilter => (defaultFilter.field.component === field)));

        const query = new DomQuery(newToolbar);
        const newFilters = query.getChild(WithClass("Add"));

        if (this.add !== null && newFilters !== null) {
            interactivityRegistration.detach(this.add);
            this.add.replaceWith(newFilters);
            this.add = newFilters;
            interactivityRegistration.attach(this.add);

            this.values.forEach(value => value.remove());
            this.values = query.getChildren(WithClass("FilterValue"));

            this.toolbar.append(...this.values);

            this.attachHiddenFilters();
            this.registerHiddenFilters();
        }

        const newFeedback = query.getChild(WithClass("Feedback"));

        this.feedback.remove();
        this.toolbar.append(newFeedback);
        this.feedback = newFeedback;

        const defaultFilters = query.getChildren(WithClass("DefaultFilter"));

        for (const defaultFilter of defaultFilters) {
            const newFilter = new Filter(defaultFilter);
            const filter = this.defaultFilters.find(element => element.name === newFilter.name);

            if (filter !== null)
                filter.reload(newFilter, reset);
        }

        if (reset)
            this.registerDefaultFilters();
    }

    reload(uri, reset) {
        const client = new HttpClient();

        client.get(
            uri + ";$Filter",
            (response) => {
                const dummy = document.createElement("div");
                dummy.innerHTML = response.responseText;

                const newFilter = dummy.childNodes[0];
                const newToolbar = newFilter.childNodes[0];

                this.reloadContents(newFilter.childNodes[2]);
                this.reloadToolbar(newToolbar, reset);

                window.history.pushState(
                    { url: uri },
                    null,
                    uri
                );
            },
            this.progress
        );
    }

    getQuery() {
        let query = "";

        for (const field of this.fields) {
            const name = field.getName();
            const value = field.getValue();

            if (value !== null && value.length > 0)
                query = query + encodeURIComponent(name) + "=" + encodeURIComponent(value) + ";";
        }

        if (query.length > 0)
            query = query.substring(0, query.length - 1);

        return query;
    }

    getUri() {
        let uri = this.baseUri;
        const query = this.getQuery();

        if (query.length > 0)
            uri = uri + ";" + query;

        return uri;
    }

    registerFilter(field, defaultFilter) {
        this.fields.push(field.component);

        if (defaultFilter)
            field.component.addEventListener("change", (event) => { this.filter(); });
    }

    toggleAddForm() {
        this.addExpanded.toggle();

        const listener = connectClickOutsideListener(
            this.add,
            (event) => {
                this.addExpanded.toggle();
                removeClickOutsideListener(listener);
            }
        );
    }
}

class Filter extends WebPageComponentClass {
    constructor(element) {
        super(element);

        const query = new DomQuery(this.element);

        this.field = query.getChild(WithClass("Field"));
        this.problems = query.getChild(WithClass("Problems"));
    }

    reload(filter, reset) {
        this.problems.replaceWith(filter.problems);
        this.problems = filter.problems;

        this.element.className = filter.element.className;

        if (reset) {
            interactivityRegistration.detach(this.field);
            this.field.replaceWith(filter.field);
            this.field = filter.field;
            interactivityRegistration.attach(this.field);
        }
    }

    get name() {
        return this.element.dataset.Name;
    }
}

interactivityRegistration.register("Filtering", function (element) { return new WebSiteObjectFilters(element); });
